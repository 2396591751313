<template>
  <div class="page-container">
    <div class="transactions">
      <div class="container-fluid">
        <h2 class="mb-4">Transactions</h2>
        <div v-if="!isAdmin && !user.merchantId">
          <p class="text-danger">You don't have permission to view this page.</p>
        </div>
        <template v-else>
          <div class="table-container table-responsive" :class="{ 'dark-theme': isDarkTheme }">
            <DataTable lazy v-model:expandedRows="expandedRows" :value="transactions" dataKey="id" :loading="isLoading"
                       :rows="pageSize" paginator responsiveLayout="stack" :totalRecords="transactionsTotal"
                       @page="changePage($event)" :rowsPerPageOptions="[5, 10, 20, 30]" :rowHover="true"
                       paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                       currentPageReportTemplate="{first} to {last} of {totalRecords}" :paginatorPosition="'bottom'"
                       :alwaysShowPaginator="true" :loading-text="'Loading transactions...'" :resizableColumns="true" style="width: 100%">
              <template #header>
                <div class="table-header p-fluid">
                  <div class="p-field">
                    <Dropdown id="collab-select" v-model="selectedCollabId" checkmark
                              :disabled="isLoading || search !== ''" :options="formattedCollabs" optionValue="id"
                              optionLabel="label" :filter="true" :placeholder="isAdmin ? 'Select Collab' : 'Select Provider'"
                              class="w-full" :class="{ 'p-input-dark': isDarkTheme }">
                      <template #option="slotProps">
                        <div v-if="isAdmin" class="flex align-items-center">
                          {{ slotProps.option.merchant.name }} - {{ slotProps.option.provider.name }}
                        </div>
                        <div v-else>
                          {{ slotProps.option.provider.name }}
                        </div>
                      </template>
                    </Dropdown>
                  </div>
                  <div class="p-field">
                    <Dropdown id="transaction-type" v-model="transactionTypeFilter" :options="transactionTypeOptions"
                              optionLabel="label" optionValue="value" placeholder="Select Transaction Type"
                              :disabled="isLoading || search !== ''" class="w-full" :class="{ 'p-input-dark': isDarkTheme }">
                    </Dropdown>
                  </div>
                  <div class="p-field">
                    <Dropdown id="transaction-status" v-model="transactionStatusFilter"
                              :options="transactionStatusOptions"
                              optionLabel="label" optionValue="value"
                              placeholder="Select Transaction Status"
                              :disabled="isLoading || search !== ''" class="w-full" :class="{ 'p-input-dark': isDarkTheme }">
                    </Dropdown>
                  </div>
                  <div class="p-field">
                    <Calendar id="date-range" v-model="dateRange" selectionMode="range" :manualInput="false"
                              :disabled="isLoading || search !== ''" :showButtonBar="true" :max-date="new Date()"
                              :min-date="new Date(2024, 9, 1)" showIcon iconDisplay="input"
                              :numberOfMonths="2"
                              :showOtherMonths="true" :selectOtherMonths="true" dateFormat="yy-mm-dd"
                              placeholder="Select Date Range" :class="{ 'p-input-dark': isDarkTheme }" class="w-full" />
                  </div>
                  <div class="p-field">
                    <div class="search-field">
                      <IconField iconPosition="right">
                        <InputIcon class="pi pi-barcode"> </InputIcon>
                        <InputText :disabled="isLoading" id="search" v-model="search"
                                   placeholder="Transaction ID or Reference ID" class="w-full" />
                      </IconField>
                    </div>
                  </div>
                  <div>
                    <Button icon="pi pi-filter-slash" @click="clearFilters" text />
                  </div>
                  <div>
                    <Button label="Search" icon="pi pi-search" @click="fetchTransactions" />
                  </div>
                </div>
              </template>

              <template #empty>
                <div v-if="!isLoading" class="d-flex justify-content-center m-3">
                  <p>No transactions to be displayed</p>
                </div>
              </template>

              <Column expander style="width: 1rem" />
              <Column field="id" header="ID" :headerStyle="{ position: 'sticky', top: '0' }" class="id-column">
                <template #body="{ data }">
                  <div class="id-cell">{{ data.id }}</div>
                </template>
              </Column>
              <Column field="amount" header="Amount">
                <template #body="{ data }">
                  <span v-if="data.transactionType == 1" class="text-success strong">+{{ data.amount }} {{ data.currency }}</span>
                  <span v-else class="text-danger">-{{ data.amount }} {{ data.currency }}</span>

                </template>
              </Column>
              <Column field="createdUtc" header="Created">
                <template #body="{ data }">
                  {{ formatDate(data.createdUtc) }}
                </template>
              </Column>
              <Column field="updatedUtc" header="Updated">
                <template #body="{ data }">
                  {{ formatDate(data.updatedUtc) }}
                </template>
              </Column>
              <Column field="transactionStatusMessage" header="Status"></Column>
              <Column field="callbackDeliveryStatus" header="Callback"></Column>
              <Column field="actions" header="Actions">
                <template #body="{ data }">
                  <div class="button-group">
                    <Button label="Edit" icon="pi pi-pen-to-square"
                            @click="showEditForm(data.id, data.transactionStatus)"
                            class="p-button-outlined p-button-secondary" />
                    <Button label="Callbacks" icon="pi pi-external-link"
                            @click="showCallbackAttempts(data.id)"
                            class="p-button-outlined p-button-secondary" />
                    <Button label="Logs" icon="pi pi-list"
                            @click="showTransactionLogs(data.id)"
                            class="p-button-outlined p-button-secondary" />
                  </div>
                </template>
              </Column>

              <template #expansion="{ data }">
                <div class="card-row">
                  <div class="info-card main-info">
                    <div class="card-header">
                      <i class="pi pi-info-circle"></i>
                      <h3>Transaction Details</h3>
                    </div>
                    <div class="info-row">
                      <span class="info-label">Collab:</span>
                      <span class="info-value">{{ data.merchantName }} - {{ data.providerName }}</span>
                    </div>
                    <div class="info-row">
                      <span class="info-label">Type:</span>
                      <span class="info-value" :class="{ 'cash-in': data.transactionType == 1, 'cash-out': data.transactionType == 2 }">
            <i :class="data.transactionType == 1 ? 'pi pi-arrow-down' : 'pi pi-arrow-up'"></i>
            {{ data.transactionType == 1 ? 'Cash In' : 'Cash Out' }}
          </span>
                    </div>
                    <div class="info-row">
                      <span class="info-label">Internal Status:</span>
                      <span class="info-value status-badge" :class="getStatusClass(data.transactionStatus)">
            <i :class="getStatusIcon(data.transactionStatus)"></i>
            {{ getStatusLabel(data.transactionStatus) }}
          </span>
                    </div>
                    <div v-if="data.destinationAmount" class="info-row">
                      <span class="info-label">Destination Amount:</span>
                      <span class="info-value">
            <i class="pi pi-money-bill"></i>
            {{ data.destinationAmount }} {{ data.destinationCurrency }}
          </span>
                    </div>
                  </div>

                  <div class="info-card wide-card ids">
                    <div class="card-header">
                      <i class="pi pi-id-card"></i>
                      <h3>Identifiers</h3>
                    </div>
                    <div v-if="data.transactionReferenceId" class="info-row-id">
                      <span class="info-label"><i class="pi pi-hashtag"></i> Reference ID:</span>
                      <div class="info-value-with-copy">
                        <span class="info-value">{{ data.transactionReferenceId }}</span>
                        <Button type="button" icon="pi pi-copy" class="p-button-text p-button-rounded" @click="copyToClipboard(data.transactionReferenceId)" />
                      </div>
                    </div>
                    <div class="info-row-id">
                      <span class="info-label"><i class="pi pi-flag"></i> Provider TID:</span>
                      <div class="info-value-with-copy">
                        <span class="info-value">{{ data.providerTransactionId ?? "-" }}</span>
                        <Button type="button" icon="pi pi-copy" class="p-button-text p-button-rounded" @click="copyToClipboard(data.providerTransactionId)" />
                      </div>
                    </div>
                    <div class="info-row-id">
                      <span class="info-label"><i class="pi pi-user"></i> Merchant CID:</span>
                      <div class="info-value-with-copy">
                        <span class="info-value">{{ data.merchantClientId ?? "-" }}</span>
                        <Button type="button" icon="pi pi-copy" class="p-button-text p-button-rounded" @click="copyToClipboard(data.merchantClientId)" />
                      </div>
                    </div>
                    <div class="info-row-id">
                      <span class="info-label"><i class="pi pi-key"></i> Nonce:</span>
                      <div class="info-value-with-copy">
                        <span class="info-value">{{ data.nonce ?? "-" }}</span>
                        <Button type="button" icon="pi pi-copy"
                                class="p-button-text p-button-rounded" @click="copyToClipboard(data.nonce)" />
                      </div>
                    </div>
                  </div>

                  <div class="info-card additional-data">
                    <div class="card-header">
                      <i class="pi pi-list"></i>
                      <h3>Additional Data</h3>
                    </div>
                    <pre class="json-data info">{{ formatJsonData(data.accountData) }}</pre>
                  </div>

                  <div v-if="data.errorMessage" class="info-card error-message">
                    <div class="card-header icon-red">
                      <i class="pi pi-exclamation-triangle"></i>
                      <h3>Error</h3>
                    </div>
                    <pre class="json-data error">{{ formatErrorData(data.errorMessage) }}</pre>
                  </div>
                </div>
              </template>

              <template #paginatorstart>
                <div v-if="error" class="text-danger p-2">{{ error }}</div>
                <Button type="button" icon="pi pi-refresh" text @click="fetchTransactions" />
              </template>
            </DataTable>
          </div>
        </template>
      </div>
      <Dialog v-model:visible="showCallbackAttemptsModal" header="Callback Attempts" :modal="true" :maximizable="true"
              :style="{ width: '80rem' }" :breakpoints="{ '1199px': '80vw', '575px': '90vw' }" :darkMode="isDarkTheme">
        <DataTable :value="callbackAttempts" :loading="isLoading" :rows="10" class="callback-attempts-table dark-table">

          <template #empty>
            <div v-if="!isLoading" class="d-flex justify-content-center m-3">
              <p>No callbacks to be displayed</p>
            </div>
          </template>

          <Column field="id" header="ID" class="id-column"></Column>
          <Column field="createdUtc" header="Created">
            <template #body="{ data }">
              {{ formatDate(data.createdUtc) }}
            </template>
          </Column>
          <Column field="callbackUrl" header="URL"></Column>
          <Column field="requestBody" header="Body">
            <template #body="{ data }">
              <Button :label="data.showRequestBody ? 'Hide' : 'Show'" @click="toggleRequestBody(data)"
                      class="p-button-outlined p-button-secondary p-button-sm" />
              <pre v-if="data.showRequestBody" class="small mt-4">{{ formatJsonData(data.requestBody) }}</pre>
            </template>
          </Column>
          <Column field="responseCode" header="Code"></Column>
          <Column field="callbackAttemptStatus" header="Status"></Column>
          <Column field="actions" header="Actions">
            <template #body="{ data }">
              <div>
                <Button v-if="!hasCallbackAttemptBeenRetried(data)" label="Retry" @click="retryCallback(data.id)"
                        class="p-button-sm p-button-secondary" :loading="isRetryingCallbackForId(data)" />
                <div v-else>Request sent</div>
              </div>
            </template>
          </Column>
        </DataTable>
      </Dialog>

      <Dialog v-model:visible="showLogsModal"
              header="Transaction Logs"
              :modal="true"
              :maximizable="true"
              :style="{ width: '90vw' }"
              :contentStyle="{ height: '70vh' }"
              :breakpoints="{ '1199px': '90vw', '575px': '95vw' }"
              :darkMode="isDarkTheme">
        <LogViewer
            v-if="showLogsModal"
            :transaction-id="selectedTransactionId"
            :is-dark-theme="isDarkTheme"
        />
      </Dialog>

      <Dialog v-model:visible="showEditFormModal"
              header="Edit transaction"
              :modal="true"
              :maximizable="true"
              :style="{ width: '90vw' }"
              :contentStyle="{ height: '70vh' }"
              :breakpoints="{ '1199px': '90vw', '575px': '95vw' }"
              :darkMode="isDarkTheme">
        <EditTransaction
            v-if="showEditFormModal"
            :transaction-id="selectedTransactionId"
            :current-status-id="transactionStatusId"
            :is-dark-theme="isDarkTheme"
        />
      </Dialog>

      <Toast />
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";
import {formatError} from "vue-loader/dist/formatError";
import LogViewer from '../components/transactions/TransactionLogComponent.vue';
import EditTransaction from '../components/transactions/TransactionEditComponent.vue';

export default {
  components: {
    DataTable,
    Column,
    Dropdown,
    Button,
    InputText,
    Dialog,
    Calendar,
    IconField,
    InputIcon,
    Toast,
    LogViewer,
    EditTransaction,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: 'TransactionsPage',
  data() {
    return {
      selectedCollabId: '',
      currentPage: 0,
      pageSize: 5,
      transactions: [],
      transactionsTotal: 0,
      transactionsTotalPages: 0,
      callbackAttempts: [],
      currentRetryingIds: [],
      retriedCallbackAttempts: [],
      isLoading: false,
      isLoadingCollabs: false,
      isRetryingCallback: false,
      error: null,
      retryError: null,
      showCallbackAttemptsModal: false,
      search: '',
      dateRange: null,
      transactionTypeFilter: null,
      transactionStatusFilter: null,
      transactionTypeOptions: [
        { label: 'All', value: null },
        { label: 'Cash In', value: 1 },
        { label: 'Cash Out', value: 2 }
      ],
      transactionStatusOptions: [
        { label: 'All', value: null },
        { label: 'Success', value: 4 },
        { label: 'Pending', value: 3 },
        { label: 'Scheduled', value: 2 },
        { label: 'Not Confirmed', value: 1 },
        { label: 'Cancelled', value: -1 },
        { label: 'PSP Error', value: -2 },
        { label: 'PSP Generic', value: -3 },
        { label: 'Network Error', value: -4 },
        { label: 'Internal Error', value: -5 },
        { label: 'Validation Failed', value: -6 },
        { label: 'Unknown', value: 0 },
      ],
      expandedRows: [],
      showLogsModal: false,
      selectedTransactionId: null,
      transactionStatusId: null,
      showEditFormModal: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('providers', ['providers']),
    ...mapState('collabs', {
      ...mapState('collabs', ['collabs']),
    }),
    isDarkTheme() {
      return this.$store.state.isDarkTheme;
    },
    isAdmin() {
      return this.user && this.user.roles.includes('Admin');
    },
    formattedCollabs() {
      return this.collabs.map(collab => ({
        ...collab,
        label: this.isAdmin
            ? `${collab.merchant.name} - ${collab.provider.name}`
            : collab.provider.name
      }));
    },
    first() {
      return this.currentPage * this.pageSize + 1;
    },
    last() {
      return Math.min(this.currentPage * this.pageSize + this.pageSize, this.transactionsTotal);
    },
  },
  async created() {
    this.isLoadingCollabs = true;
    try {
      if (this.isAdmin) {
        const providers = await this.fetchProviders();
        await this.fetchCollabsByProviderIds(providers.map(provider => provider.id));
      }
      else {
        await this.fetchCollabsByMerchantId(this.user.merchantId);
      }
    } catch (error) {
      this.error = 'Failed to load collaborations: ' + error.message;
    } finally {
      this.isLoadingCollabs = false;
    }
  },
  mounted() {
    document.title = "Transactions";
  },
  methods: {
    formatError,
    ...mapActions('providers', ['fetchProviders']),
    ...mapActions('collabs', ['fetchCollabsByMerchantId', 'fetchCollabsByProviderIds']),
    ...mapActions('transactions', [
      'fetchTransactionById',
      'fetchTransactionByReferenceId',
      'fetchTransactionsByCollabId',
      'fetchCallbackAttemptsByTransactionId',
      'retryCallbackAttempt']),

    clearFilters() {
      this.selectedCollabId = '';
      this.transactionTypeFilter = null;
      this.transactionStatusFilter = null;
      this.dateRange = null;
      this.search = '';
      this.fetchTransactions();
    },

    async fetchTransactions() {
      try {
        this.error = null;
        this.isLoading = true;

        if (this.search) {
          await this.searchTransactions();
        } else {
          const startDate = this.dateRange ? this.dateRange[0].toISOString() : undefined;
          const endDate = this.dateRange ? this.dateRange[1].toISOString() : undefined;
          const transactionType = this.transactionTypeFilter;
          const transactionStatus = this.transactionStatusFilter;
          const response = await this.fetchTransactionsByCollabId({
            collabId: this.selectedCollabId ? this.selectedCollabId : 0,
            transactionType: transactionType,
            transactionStatus: transactionStatus,
            page: this.currentPage + 1,
            size: this.pageSize,
            startDate: startDate,
            endDate: endDate,
          });
          this.transactions = response.transactions;
          this.transactionsTotal = response.totalCount;
          this.transactionsTotalPages = response.totalPages;
          this.currentPage = response.currentPage - 1;
        }
      } catch (error) {
        this.error = 'Failed to fetch transactions. Please try again.';
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch transactions: ' + error.message, life: 5500 });
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async searchTransactions() {
      if (this.search) {
        try {
          var response;
          if (this.search.match(/^\d+$/)) {
            response = await this.fetchTransactionById(parseInt(this.search));
          } else {
            response = await this.fetchTransactionByReferenceId(this.search);
          }

          if (response) {
            this.transactions = [response];
            this.transactionsTotal = 1;
          } else {
            this.transactions = [];
            this.transactionsTotal = 0;
          }

          this.transactionsTotalPages = 1;
          this.currentPage = 1;

        } catch (error) {
          this.error = 'Failed to search transactions. Please try again.';
          this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to find transaction: ' + error.message, life: 5500 });
          console.error(error);
        }
      } else {
        await this.fetchTransactions();
      }
    },

    async changePage(event) {
      this.currentPage = event.page;
      this.pageSize = event.rows;
      await this.fetchTransactions();
    },

    async changeItemsPerPage(size) {
      this.pageSize = size;
      await this.fetchTransactions();
    },

    showTransactionLogs(transactionId) {
      this.selectedTransactionId = transactionId;
      this.showLogsModal = true;
    },

    showEditForm(transactionId, transactionStatusId) {
        this.selectedTransactionId = transactionId;
        this.transactionStatusId = transactionStatusId;
        this.showEditFormModal = true;
    },

    async showCallbackAttempts(transactionId) {
      try {
        this.error = null;
        this.isLoading = true;
        const response = await this.fetchCallbackAttemptsByTransactionId({ transactionId, page: 1, size: 100 });
        this.callbackAttempts = response.callbackAttempts;
        this.showCallbackAttemptsModal = true;
      } catch (error) {
        this.error = 'Failed to fetch callback attempts. Please try again.';
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch callback attempts: ' + error.message, life: 5500 });
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async retryCallback(callbackAttemptId) {
      try {
        this.retryError = null;
        this.isRetryingCallback = true;
        this.currentRetryingIds.push(callbackAttemptId);
        this.toast.add({ severity: 'info', summary: 'Retrying...', detail: 'Adding a callback to the retry queue', life: 3000 });
        await this.retryCallbackAttempt(callbackAttemptId);
        this.retriedCallbackAttempts.push(callbackAttemptId);
        this.toast.add({ severity: 'success', summary: 'Successful',
          detail: 'Callback added to the retry queue. You will be able to check its status after a while.', life: 5500 });
      } catch (error) {
        this.retryError = 'Failed to retry callback attempt. Please try again.';
        this.toast.add({ severity: 'error', summary: 'Error',
          detail: 'Failed to add callback to the retry queue: ' + error.message, life: 6000 });
      } finally {
        this.currentRetryingIds = this.currentRetryingIds.filter(id => id !== callbackAttemptId);
        this.isRetryingCallback = false;
      }
    },

    isCallbackAttemptFailed(data) {
      return data.callbackAttemptStatus === 'Failed';
    },

    hasCallbackAttemptBeenRetried(data) {
      return this.retriedCallbackAttempts.includes(data.id);
    },

    isRetryingCallbackForId(data) {
      return this.currentRetryingIds.includes(data.id);
    },

    formatDate(date) {
      return new Date(date).toLocaleString();
    },

    toggleRequestBody(attempt) {
      attempt.showRequestBody = !attempt.showRequestBody;
    },

    getStatusLabel(status) {
      const option = this.transactionStatusOptions.find(option => option.value === status);
      return option ? option.label : '< Unknown >';
    },

    formatErrorData(error) {
      if (typeof error === 'string' && error.startsWith('{')) {
        return this.formatJsonData(error);
      }
      else return error;
    },

    formatJsonData(jsonData) {
      if (typeof jsonData === 'string') {
        try {
          jsonData = JSON.parse(jsonData);
        } catch (e) {
          return jsonData;
        }
      }
      return JSON.stringify(jsonData, null, 2);
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.toast.add({ severity: 'success', summary: 'Copied',
          detail: 'Successfully copied: ' + text, life: 2500 });
      }).catch(err => {
        this.toast.add({ severity: 'error', summary: 'Failed to copy',
          detail: 'Failed to copy: ' + err.message, life: 4000 });
      });
    },

    getStatusClass(status) {
      switch (status) {
        case 4: return 'status-success';
        case 3:
        case 2:
        case 1: return 'status-pending';
        default: return 'status-error';
      }
    },

    getStatusIcon(status) {
      switch (status) {
        case 4: return 'pi pi-check-circle';
        case 3:
        case 2:
        case 1: return 'pi pi-clock';
        default: return 'pi pi-exclamation-circle';
      }
    },
  },
};
</script>

<style scoped>

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.transactions {
  padding: 60px 10px 10px;
  display: flex;
  flex-direction: column;
}

.transaction-details pre {
  overflow-x: auto;
  max-height: 300px;
}

.table-container {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.2rem;
  border: 2px solid #e0e0e0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.table-container.dark-theme {
  background-color: #18181B;
  color: rgb(248, 248, 248);
  border-color: #41414e;
}

.p-datatable-header {
  border: none !important;
  padding: 0 !important;
}

.table-header {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
}

.table-header .p-field {
  flex: 1 1 250px;
}

.table-header .search-and-clear {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.search-field {
  flex: 3 1 50%;
}

.action-buttons {
  flex: 1 1 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-responsive {
  flex: 1;
  overflow: hidden;
}

.table-responsive .p-datatable-wrapper {
  flex: 1;
  overflow: auto;
}

.table-responsive .p-datatable-thead th,
.table-responsive .p-datatable-tbody td {
  white-space: nowrap;
  border: none !important;
}

.card-header {
  display: flex;
  margin-bottom: 1rem;
}

.card-header i {
  font-size: 1.35rem;
  margin-right: 0.5rem;
  color: var(--primary-color);
}

.card-header.icon-red i {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  color: var(--red-500);
}

.expansion-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--surface-ground);
  border-radius: 0.5rem;
}

.expansion-container.dark-theme {
  background-color: var(--surface-section);
}

.card-row {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.info-card {
  min-width: 300px;
  max-width: calc(40% - 0.5rem);
  background-color: var(--surface-card);
  border-radius: 0.8rem;
  padding: 1.35rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-card.wide-card {
  max-width: calc(100% - 0.5rem);
}

.dark-theme .info-card {
  background-color: var(--surface-ground);
  border: 1px solid var(--surface-border);
}

.info-card h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: var(--text-color);
  font-size: 1.1rem;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 1.2rem;
}

.info-row-id {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.info-label {
  font-weight: bold;
  color: var(--text-color-secondary);
}

.info-value {
  color: var(--text-color);
}

.info-label i {
  margin-right: 0.3rem;
}

.status-badge i {
  margin-right: 0.3rem;
}

.info-value i {
  margin-right: 0.3rem;
}

.info-value-with-copy {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.cash-in {
  color: var(--green-500);
}

.cash-out {
  color: var(--red-500);
}

.status-badge {
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: bold;
}

.status-success { background-color: var(--green-100); color: var(--green-900); }
.status-pending { background-color: var(--yellow-100); color: var(--yellow-900); }
.status-error { background-color: var(--red-100); color: var(--red-900); }

.dark-theme .status-success { background-color: var(--green-900); color: var(--green-100); }
.dark-theme .status-pending { background-color: var(--yellow-900); color: var(--yellow-100); }
.dark-theme .status-error { background-color: var(--red-900); color: var(--red-100); }

.json-data {
  background-color: var(--surface-section);
  border-radius: 0.35rem;
  padding: 0.55rem;
  font-size: 0.875rem;
  white-space: pre-wrap;
  word-break: break-all;
  max-height: 200px;
  overflow-y: auto;
}

.button-group {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.dark-theme .json-data {
  background-color: var(--surface-card);
}

.json-data.error {
  border-left: 4px solid var(--red-500);
}

.json-data.info {
  border-left: 4px solid var(--blue-500);
}

@media (max-width: 768px) {
  .info-card {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .transactions-table .p-datatable-wrapper {
    overflow-x: auto;
  }

  table {
    font-size: 12px;
  }

  .transactions-table .p-datatable-thead th,
  .transactions-table .p-datatable-tbody td {
    white-space: nowrap;
  }

  .table-responsive .p-datatable-wrapper {
    overflow-x: auto;
  }

  .table-responsive .p-datatable-thead th,
  .table-responsive .p-datatable-tbody td {
    white-space: nowrap;
  }

  .table-header .p-field,
  .search-field,
  .action-buttons {
    flex: 1 1 100%;
  }

  .action-buttons {
    margin-top: 1rem;
  }

  .table-header,
  .table-container {
    padding: 0.5rem;
  }
}
</style>