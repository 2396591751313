import axios from 'axios';

const BASE_URL = process.env.VUE_APP_CALLBACKS_API_BASE_URL;

export default {
    postRetryCallback(callbackAttemptId) {
        return axios.post(`${BASE_URL}/api/v1/callbacks/attempts/${callbackAttemptId}/retry`);
    },

    addToCallbackQueue(transactionId) {
        return axios.post(`${BASE_URL}/api/v1/transactions/${transactionId}/callbacks/enqueue`);
    }
}