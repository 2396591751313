<template>
  <div class="edit-transaction" :class="{ 'dark-theme': isDarkTheme }">
    <div v-if="isLoading" class="loading-container">
      <i class="pi pi-spin pi-spinner loading-spinner"></i>
      <p class="loading-text">Processing request...</p>
    </div>

    <div v-else class="edit-content">
      <!-- Status Update Section -->
      <div class="info-card status-card">
        <div class="card-header">
          <i class="pi pi-pencil header-icon"></i>
          <h3>Update Transaction Status</h3>
        </div>
        <div class="card-content">
          <div class="alert-message warning">
            <i class="pi pi-exclamation-triangle"></i>
            <span>Manually updating transaction status will override the current status. Please proceed with caution.</span>
          </div>
          <div class="status-form">
            <div class="form-row">
              <label>Current Status:</label>
              <div class="status-badge elevated" :class="getStatusClass(currentStatusId)">
                <i class="pi" :class="getStatusIcon(currentStatusId)"></i>
                <span>{{ getStatusLabel(currentStatusId) }}</span>
              </div>
            </div>
            <div class="form-row">
              <label>New Status:</label>
              <Dropdown
                  v-model="selectedStatus"
                  :options="transactionStatusOptions"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select New Status"
                  class="w-full status-dropdown"
                  :class="{ 'p-input-dark': isDarkTheme }"
              >
                <template #value="slotProps">
                  <div class="status-option" v-if="slotProps.value">
                    <i class="pi" :class="getStatusIcon(slotProps.value)"></i>
                    <span>{{ slotProps.option.label }}</span>
                  </div>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
                <template #option="slotProps">
                  <div class="status-option">
                    <i class="pi" :class="getStatusIcon(slotProps.option.value)"></i>
                    <span>{{ slotProps.option.label }}</span>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="actions">
              <Button
                  label="Update Status"
                  icon="pi pi-check"
                  @click="handleStatusUpdate"
                  :loading="isUpdatingStatus"
                  :disabled="!selectedStatus || selectedStatus === currentStatusId"
                  class="p-button-raised"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Validation Results Section -->
      <div class="info-card validation-card">
        <div class="card-header">
          <i class="pi pi-check-square header-icon"></i>
          <h3>Validation Results</h3>
          <Button
              v-if="validationResults.length > 0"
              icon="pi pi-refresh"
              class="p-button-text refresh-button"
              @click="fetchValidationResults"
              :loading="isLoadingValidations"
              tooltip="Refresh Results"
          />
        </div>
        <div class="card-content">
          <div class="validation-description">
            Manage transaction validation results and retry failed validations when needed.
          </div>

          <div v-if="isLoadingValidations" class="loading-state">
            <i class="pi pi-spin pi-spinner"></i>
            <span>Loading validations...</span>
          </div>

          <div v-else-if="validationResults.length === 0" class="empty-state">
            <i class="pi pi-info-circle empty-icon"></i>
            <span>No validation results available</span>
          </div>

          <div v-else class="validation-list">
            <TransitionGroup name="validation-item">
              <div v-for="validation in validationResults"
                   :key="validation.validationId"
                   class="validation-item">
                <div class="validation-info">
                  <div class="validation-header">
                    <div class="validation-url">
                      <i class="pi pi-link"></i>
                      <span class="url-text">{{ validation.validationUrl }}</span>
                    </div>
                    <div class="validation-status">
                      <span :class="['status-badge', getValidationStatusClass(validation.status)]">
                        <i class="pi" :class="getValidationStatusIcon(validation.status)"></i>
                        {{ validationStatusOptions.find(opt => opt.value === validation.status)?.label || 'Unknown' }}
                      </span>
                    </div>
                  </div>

                  <div class="validation-actions">
                    <Dropdown
                        v-model="validation.status"
                        :options="validationStatusOptions"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Set Status"
                        class="p-button-sm validation-dropdown"
                    />
                    <Button
                        label="Update"
                        icon="pi pi-check"
                        class="p-button-sm p-button-raised"
                        :loading="isUpdatingValidation === validation.validationId"
                        @click="handleSetValidationStatus(validation.validationId, validation.status)"
                    />
                    <Button
                        v-if="validation.isAwaitable"
                        label="Retry"
                        icon="pi pi-refresh"
                        class="p-button-sm p-button-outlined"
                        :loading="isRetryingValidation === validation.validationId"
                        @click="handleRetryValidation(validation.validationId)"
                    />
                  </div>
                </div>
              </div>
            </TransitionGroup>
          </div>
        </div>
      </div>

      <!-- Queue Operations Section -->
      <div class="info-card queue-card">
        <div class="card-header">
          <i class="pi pi-list header-icon"></i>
          <h3>Queue Operations</h3>
        </div>
        <div class="card-content">
          <div class="queue-actions">
            <div class="queue-action-item">
              <div class="queue-info">
                <h4>
                  <i class="pi pi-inbox queue-icon"></i>
                  Transaction Queue
                </h4>
                <p>Retry transaction processing by adding it to the main queue.</p>
              </div>
              <Button
                  label="Add to Queue"
                  icon="pi pi-plus"
                  @click="handleAddToTransactionQueue"
                  :loading="isAddingToTransactionQueue"
                  class="p-button-raised"
              />
            </div>

            <div class="queue-action-item">
              <div class="queue-info">
                <h4>
                  <i class="pi pi-sync queue-icon"></i>
                  Polling Queue
                </h4>
                <p>Check transaction status with the payment provider.</p>
              </div>
              <Button
                  label="Add to Queue"
                  icon="pi pi-plus"
                  @click="handleAddToPollingQueue"
                  :loading="isAddingToPollingQueue"
                  class="p-button-raised"
              />
            </div>

            <div class="queue-action-item">
              <div class="queue-info">
                <h4>
                  <i class="pi pi-bell queue-icon"></i>
                  Callback Queue
                </h4>
                <p>Retry sending transaction result to merchant endpoint.</p>
              </div>
              <Button
                  label="Add to Queue"
                  icon="pi pi-plus"
                  @click="handleAddToCallbackQueue"
                  :loading="isAddingToCallbackQueue"
                  class="p-button-raised"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast position="bottom-right" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";

export default {
  name: 'EditTransaction',
  components: {
    Dropdown,
    Button,
    Toast
  },
  props: {
    transactionId: {
      type: [Number, String],
      required: true
    },
    currentStatusId: {
      type: [Number, String],
      required: true
    },
    isDarkTheme: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isLoading: false,
      error: null,
      selectedStatus: null,
      isUpdatingStatus: false,
      isAddingToTransactionQueue: false,
      isAddingToPollingQueue: false,
      isAddingToCallbackQueue: false,
      transactionStatusOptions: [
        { label: 'Success', value: 4 },
        { label: 'Pending', value: 3 },
        { label: 'Scheduled', value: 2 },
        { label: 'Not Confirmed', value: 1 },
        { label: 'Cancelled', value: -1 },
        { label: 'PSP Error', value: -2 },
        { label: 'PSP Generic', value: -3 },
        { label: 'Network Error', value: -4 },
        { label: 'Internal Error', value: -5 },
        { label: 'Validation Failed', value: -6 },
        { label: 'Unknown', value: 0 },
      ],
      validationResults: [],
      isLoadingValidations: false,
      validationStatusOptions: [
        { label: 'Success', value: 1 },
        { label: 'Pending', value: 0 },
        { label: 'Failed', value: -1 }
      ],
      isUpdatingValidation: null,
      isRetryingValidation: null,
    };
  },
  created() {
    this.fetchValidationResults();
  },
  methods: {
    ...mapActions('transactions', [
      'updateTransactionStatus',
      'addToTransactionQueue',
      'addToPollingQueue',
      'addToCallbackQueue',
      'getValidationResults',
      'setValidationResult',
      'retryAwaitableValidation'
    ]),

    async fetchValidationResults() {
      this.isLoadingValidations = true;
      try {
        const response = await this.getValidationResults(this.transactionId);
        this.validationResults = response.data;
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Failed to Load',
          detail: `Could not load validation results: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isLoadingValidations = false;
      }
    },

    async handleSetValidationStatus(validationId, newStatus) {
      this.isUpdatingValidation = validationId;
      try {
        await this.setValidationResult({ validationId, status: newStatus });
        await this.fetchValidationResults(); // Refresh the list
        this.toast.add({
          severity: 'success',
          summary: 'Status Updated',
          detail: 'Validation status was successfully updated',
          life: 3000
        });
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Update Failed',
          detail: `Failed to update validation status: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isUpdatingValidation = null;
      }
    },

    async handleRetryValidation(validationId) {
      this.isRetryingValidation = validationId;
      try {
        await this.retryAwaitableValidation(validationId);
        await this.fetchValidationResults(); // Refresh the list
        this.toast.add({
          severity: 'success',
          summary: 'Validation Retried',
          detail: 'Validation retry was triggered successfully',
          life: 3000
        });
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Retry Failed',
          detail: `Failed to retry validation: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isRetryingValidation = null;
      }
    },

    getValidationStatusClass(status) {
      if (status === 1) return 'status-success';
      if (status === 0) return 'status-pending';
      return 'status-error';
    },

    getStatusLabel(status) {
      const option = this.transactionStatusOptions.find(option => option.value === parseInt(status));
      return option ? option.label : 'Unknown';
    },

    getStatusClass(status) {
      const statusInt = parseInt(status);
      if (statusInt === 4) return 'status-success';
      if (statusInt > 0) return 'status-pending';
      if (statusInt <= 0) return 'status-error';
      return 'status-unknown';
    },

    async handleStatusUpdate() {
      if (!this.selectedStatus) return;

      this.isUpdatingStatus = true;
      try {
        await this.updateTransactionStatus({
          transactionId: this.transactionId,
          status: this.selectedStatus
        });
        this.toast.add({
          severity: 'success',
          summary: 'Status Updated',
          detail: `Transaction status successfully updated to ${this.getStatusLabel(this.selectedStatus)}`,
          life: 3000
        });
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Update Failed',
          detail: `Failed to update status: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isUpdatingStatus = false;
      }
    },

    async handleAddToTransactionQueue() {
      this.isAddingToTransactionQueue = true;
      try {
        const response = await this.addToTransactionQueue(this.transactionId);
        if (response?.status === 202) {
          this.toast.add({
            severity: 'success',
            summary: 'Added to Queue',
            detail: 'Transaction successfully added to processing queue',
            life: 3000
          });
        }
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Queue Operation Failed',
          detail: `Failed to add to transaction queue: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isAddingToTransactionQueue = false;
      }
    },

    async handleAddToPollingQueue() {
      this.isAddingToPollingQueue = true;
      try {
        const response = await this.addToPollingQueue(this.transactionId);
        if (response?.status === 202) {
          this.toast.add({
            severity: 'success',
            summary: 'Added to Queue',
            detail: 'Transaction successfully added to polling queue',
            life: 3000
          });
        }
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Queue Operation Failed',
          detail: `Failed to add to polling queue: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isAddingToPollingQueue = false;
      }
    },

    async handleAddToCallbackQueue() {
      this.isAddingToCallbackQueue = true;
      try {
        const response = await this.addToCallbackQueue(this.transactionId);
        if (response?.status === 202) {
          this.toast.add({
            severity: 'success',
            summary: 'Added to Queue',
            detail: 'Transaction successfully added to callback queue',
            life: 3000
          });
        }
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Queue Operation Failed',
          detail: `Failed to add to callback queue: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isAddingToCallbackQueue = false;
      }
    },

    getStatusIcon(status) {
      const statusInt = parseInt(status);
      if (statusInt === 4) return 'pi-check-circle';
      if (statusInt > 0) return 'pi-clock';
      if (statusInt <= 0) return 'pi-times-circle';
      return 'pi-question-circle';
    },

    getValidationStatusIcon(status) {
      if (status === 1) return 'pi-check';
      if (status === 0) return 'pi-clock';
      return 'pi-times';
    },
  }
};
</script>

# The template and script sections remain the same, updating only the style section:

<style scoped>
/* Base Layout */
.edit-transaction {
  padding: 2rem;
  height: 100%;
  overflow-y: auto;
  background-color: var(--surface-ground);
}

.edit-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* Loading States */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 1.5rem;
}

.loading-spinner {
  font-size: 3rem;
  color: var(--primary-color);
}

.loading-text {
  font-size: 1.25rem;
  color: var(--text-color-secondary);
  font-weight: 500;
}

/* Card Styles */
.info-card {
  background-color: var(--surface-card);
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-in-out;
}

.info-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--surface-border);
  gap: 1rem;
}

.header-icon {
  font-size: 1.75rem;
  color: var(--primary-color);
  flex-shrink: 0;
}

.card-header h3 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--text-color);
  font-weight: 600;
  flex-grow: 1;
}

.refresh-button {
  margin-left: auto;
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
}

/* Form Elements */
.form-row {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.form-row label {
  min-width: 140px;
  font-weight: 500;
  color: var(--text-color-secondary);
}

.status-dropdown {
  flex-grow: 1;
}

/* Status Badges */
.status-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1.25rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.01em;
}

.status-badge.elevated {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.status-success {
  background-color: var(--green-50);
  color: var(--green-800);
}

.status-pending {
  background-color: var(--yellow-50);
  color: var(--yellow-800);
}

.status-error {
  background-color: var(--red-50);
  color: var(--red-800);
}

.status-unknown {
  background-color: var(--surface-200);
  color: var(--surface-800);
}

/* Alert Messages */
.alert-message {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.25rem;
  border-radius: 0.75rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.alert-message.warning {
  background-color: var(--yellow-50);
  color: var(--yellow-900);
  border-left: 4px solid var(--yellow-500);
}

/* Validation Section */
.validation-description {
  color: var(--text-color-secondary);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.validation-list {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.validation-item {
  background-color: var(--surface-section);
  border-radius: 0.75rem;
  padding: 1.5rem;
  transition: all 0.2s ease;
}

.validation-item:hover {
  background-color: var(--surface-hover);
}

.validation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  gap: 1rem;
}

.validation-url {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--text-color-secondary);
}

.url-text {
  font-family: 'Fira Code', 'Courier New', monospace;
  background-color: var(--surface-ground);
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  letter-spacing: -0.01em;
}

.validation-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.validation-dropdown {
  min-width: 200px;
}

/* Queue Operations */
.queue-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.queue-action-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background-color: var(--surface-section);
  border-radius: 0.75rem;
  transition: all 0.2s ease;
}

.queue-action-item:hover {
  transform: translateX(4px);
  background-color: var(--surface-hover);
}

.queue-info {
  flex: 1;
}

.queue-info h4 {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 0 0 0.5rem 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-color);
}

.queue-info p {
  margin: 0;
  color: var(--text-color-secondary);
  line-height: 1.5;
}

.queue-icon {
  font-size: 1.25rem;
  color: var(--primary-color);
}

/* Empty States */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  gap: 1rem;
  color: var(--text-color-secondary);
  text-align: center;
}

.empty-icon {
  font-size: 2.5rem;
  opacity: 0.5;
  margin-bottom: 0.5rem;
}

/* Dark Theme Adjustments */
.dark-theme {
  .info-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .status-success {
    background-color: var(--green-900);
    color: var(--green-100);
  }

  .status-pending {
    background-color: var(--yellow-900);
    color: var(--yellow-100);
  }

  .status-error {
    background-color: var(--red-900);
    color: var(--red-100);
  }

  .status-unknown {
    background-color: var(--surface-700);
    color: var(--surface-50);
  }

  .alert-message.warning {
    background-color: var(--yellow-900);
    color: var(--yellow-100);
    border-left-color: var(--yellow-500);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .edit-transaction {
    padding: 1.5rem;
  }

  .info-card {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .edit-transaction {
    padding: 1rem;
  }

  .info-card {
    padding: 1.25rem;
  }

  .card-header {
    margin-bottom: 1.5rem;
  }

  .form-row {
    flex-direction: column;
    align-items: stretch;
    gap: 0.75rem;
  }

  .form-row label {
    min-width: unset;
  }

  .validation-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .validation-actions {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .queue-action-item {
    flex-direction: column;
    text-align: center;
    gap: 1.25rem;
  }

  .queue-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* Animations */
.validation-item-enter-active,
.validation-item-leave-active {
  transition: all 0.3s ease-in-out;
}

.validation-item-enter-from,
.validation-item-leave-to {
  opacity: 0;
  transform: translateX(-1rem);
}
</style>
